<template>
  <div class="pa-2 ma-2">
    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="pa-2">
          <v-card-title class="font-weight-black mx-2 py-0">
            <h5>General Settings (Staff) </h5>
            <v-spacer></v-spacer>
            <v-btn color="success" class="mr-4" @click="SaveUpdateHandler" tile> Save </v-btn>
          </v-card-title>
          <v-divider class="mx-2 my-2"></v-divider>
          <v-row class="mt-2">
            <v-col cols="12" class="d-flex">
              <v-checkbox hide-details dense v-model="modelObj.clockInEnabled"></v-checkbox>
              <span class="mr-2 my-auto py-auto">Enable ClockIn Option </span>
            </v-col>
            <template v-if="modelObj.clockInEnabled">
              <!-- <v-col cols="12" lg="4" class="d-flex">
                <span class="mx-3 my-auto mt-4">ClockIn Type</span>
                <v-radio-group mandatory row hide-details dense disabled v-model="modelObj.clockInType">
                  <v-radio label="Web ClockIn" value="web-clockin"></v-radio>
                  <v-radio label="Device ClockIn" value="day-wise"></v-radio>
                </v-radio-group>
              </v-col> -->
              <v-col cols="12" class="d-flex">
                <v-checkbox hide-details dense></v-checkbox>
                <span class="mr-2 my-auto py-auto">Show Clockin for specific time alone </span>
                <span> 
                  <v-text-field label="from" style="max-width: 100px;" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
                <span> 
                  <v-text-field label="to" style="max-width: 100px;" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-checkbox hide-details dense></v-checkbox>
                <span class="mr-2 my-auto py-auto">Enable Minimum Hours </span>
                <span> 
                  <v-text-field label="for day" style="max-width: 100px;" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
                <span> 
                  <v-text-field label="for half day" style="max-width: 100px;" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-checkbox hide-details dense></v-checkbox>
                <span class="mr-2 my-auto py-auto">Enable Maximum Hours </span>
                <span> 
                  <v-text-field label="for day" style="max-width: 100px;" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
                <span> 
                  <v-text-field label="for half day" style="max-width: 100px;" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
              </v-col>
              <v-col cols="12"> Shift Reminder </v-col>
              <v-col cols="12" class="d-flex">
                <v-checkbox hide-details dense></v-checkbox>
                <span class="mr-2 my-auto py-auto">Enable Checkin Reminder </span>
                <span> 
                  <v-text-field suffix="hours" type="number" label="before shift's start" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
                <span> 
                  <v-text-field suffix="hours" type="number" label="after shift's start" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-checkbox hide-details dense></v-checkbox>
                <span class="mr-2 my-auto py-auto">Enable Checkout Reminder </span>
                <span> 
                  <v-text-field suffix="hours" type="number"  label="before shift's end" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
                <span> 
                  <v-text-field suffix="hours" type="number"  label="after shift's end" outlined hide-details dense class="mx-2"></v-text-field>
                </span>
              </v-col>
            </template>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <data-iterator :headers="payloadHeaders" :payload="payload"></data-iterator>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card elevation="0" tile>
        <v-card-title class="primary darken-2 white--text">
          <span class="body-1 font-weight-medium">
            {{ attendanceTypeObj._id ? 'Update attendance type' : 'Add attendance type' }}
          </span>
        </v-card-title>
        <v-card-text >
          <v-form ref="form">
            <v-row class="mt-3">
              <v-col class="py-1" cols="6">
                <v-text-field label="Name" dense hide-details outlined v-model="attendanceTypeObj.name" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col class="py-1" cols="6">
                <v-text-field label="Percentage" type="number" dense hide-details outlined v-model="attendanceTypeObj.attendancePercentage"></v-text-field>
              </v-col>
              <v-col class="py-1" cols="6">
                <v-select :items="attendanceApplicableTypes" item-text="text" item-value="value" dense hide-details outlined v-model="attendanceTypeObj.applicableTo" :rules="setValidation('requiredValidation')"></v-select>
              </v-col>
              <v-col class="py-1" cols="12" lg="6">
                <v-menu ref="menu" v-model="attendanceTypeObj.menu" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="auto">
                  <template #activator="{ on, attrs }">
                    <v-text-field hide-details outlined dense v-model="attendanceTypeObj.color" label="Color" append-icon="mdi-format-color-fill" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-color-picker mode="hexa" v-model="attendanceTypeObj.colorObj" scrollable @change="attendanceTypeObj.color = attendanceTypeObj.colorObj.hexa || ''"></v-color-picker>
                  <v-card tile flat>
                    <div>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="attendanceTypeObj.menu = false" > Cancel </v-btn>
                      <v-btn text color="primary" @click="attendanceTypeObj.color = attendanceTypeObj.colorObj.hexa || ''; attendanceTypeObj.menu = false" > OK </v-btn>
                    </div>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6" class="pa-1" align-self="center">
                <v-checkbox outlined dense hide-details label="Is Active" v-model="attendanceTypeObj.isactive" class="ma-0"></v-checkbox>
              </v-col>
              <v-col cols="12" lg="6" class="pa-1" align-self="center">
                <v-checkbox outlined dense hide-details label="Is Default" v-model="attendanceTypeObj.isdefault" class="ma-0"></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveUpdateAttendanceTypeHandler()"> {{ attendanceTypeObj._id ? 'Update' : 'Save' }} </v-btn>
          <v-btn @click="dialog = false; $refs.form.reset(); attendanceTypeObj = {};" color="error"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      modelObj: {},
      attendanceTypeObj: {},
      payload: {
        hideTop: true,
        title: 'Attendance Types',
        create: () => this.openDialog(),
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          show: (item) => !item.isinternal,
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      },
    }
  },
  computed: {
    attendanceApplicableTypes () {
      return [{
        text: 'Student',
        value: 1
      }, {
        text: 'Staff',
        value: 2
      }, {
        text: 'Both (Student & Staff)',
        value: 3
      }]
    },
    payloadHeaders () {
      return [{
      //   text: 'Code',
      //   align: 'start',
      //   sortable: false,
      //   value: 'code'
      // }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Is Active',
        value: 'isactive',
        type: 'BOOLEAN'
      }, {
        text: 'Is Default',
        value: 'isdefault',
        type: 'BOOLEAN'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  mounted () {
    this.getSetting()
    this.getAttendanceTypesList()
  },
  methods: {
    openDialog () {
      this.attendanceTypeObj = {
        isactive: true
      }
      this.dialog = true
    },
    getAttendanceTypesList () {
      this.$_execute('get', 'attendance-types')
        .then(({ data }) => {
          this.payload = { ...this.payload, items: data }
        })
    },
    editHandler (id) {
      this.$_execute('get', `attendance-types/${id}`)
        .then(({ data }) => {
          this.attendanceTypeObj = data
          this.dialog = true
        })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'attendance-types',
        _id: id,
        onResolve: () => {
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    },
    getSetting () {
      this.$_execute('get', 'attendanceSetting').then(({ data }) => {
        this.modelObj = data ? data : {}
      })
    },
    SaveUpdateHandler () {
      this.$_execute('post', 'attendanceSetting', this.modelObj).then(() => {
        this.$root.$emit('snackbar', { message: 'Updated Successfully', color: 'success'})
      })
    },
    saveUpdateAttendanceTypeHandler () {
      if (this.$refs.form && this.$refs.form.validate()) {
        let url = (this.attendanceTypeObj._id) ? `attendance-types/${this.attendanceTypeObj._id}` : `/attendance-types`
        let method = (this.attendanceTypeObj._id) ? `put` : `post`
        this.$_execute(method, url, this.attendanceTypeObj)
          .then(() => {
            this.$root.$emit('snackbar', { message: this.attendanceTypeObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
            this.$refs.form.reset()
            this.attendanceTypeObj = {}
            this.getAttendanceTypesList()
          })
          .finally(() => {
            this.dialog = false
          })
      } else {
        this.$root.$emit('snackbar', {
          color: 'error',
          message: 'Please check the required fields!'
        })
      }
    }
  }
}
</script>
